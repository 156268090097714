<template>
    <div ref="target" class="input-group-container guests has-value">
        <label class="input-group-title">{{ title }}</label>
        <div class="input-group">
            <div class="input-icon-append">
                <NuxtIcon name="ri:arrow-down-s-line" />
            </div>
            <input
                :id="`form-input-${name}`"
                type="text"
                :name="name"
                :value="`${rooms} Room${rooms > 1 ? 's' : ''}, ${guests} Guest${guests > 1 ? 's' : ''}`"
                class="tooltip-handle"
                readonly
                @click="toggleDropdown()"
            />
            <div class="tooltip">{{ tooltip }}</div>
            <div v-show="dropdown" class="dropdown-select">
                <div class="dropdown-select-item flex justify-between">
                    <div class="dropdown-select-item-title">{{ `${rooms} Room${rooms > 1 ? 's' : ''}` }}</div>
                    <div class="dropdown-select-item-selectors">
                        <div class="button" :class="{ disabled: rooms <= 1 }" @click="addRooms(false)">
                            <NuxtIcon name="ri:indeterminate-circle-line" />
                        </div>
                        <div class="button" :class="{ disabled: rooms >= 100 }" @click="addRooms(true)">
                            <NuxtIcon name="ri:add-circle-line" />
                        </div>
                    </div>
                </div>
                <div class="dropdown-select-item flex justify-between">
                    <div class="dropdown-select-item-title">{{ `${guests} Guest${guests > 1 ? 's' : ''}` }}</div>
                    <div class="dropdown-select-item-selectors">
                        <div class="button" :class="{ disabled: guests <= 1 }" @click="addGuests(false)">
                            <NuxtIcon name="ri:indeterminate-circle-line" />
                        </div>
                        <div class="button" :class="{ disabled: guests >= 100 }" @click="addGuests(true)">
                            <NuxtIcon name="ri:add-circle-line" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props: any = defineProps({
    name: { type: String, required: true, default: 'Airport' },
    title: { type: String, required: true, default: 'Title' },
    tooltip: { type: String, required: true, default: 'Tooltip' },
    value: { type: Object, required: false, default: () => ({ guests: 2, rooms: 1 }) },
});

// Constant variable for initialising emit events
const emit: any = defineEmits(['update:value']);

// Component Ref
const target: any = ref(null);

// Dropdown Status Ref
const dropdown: any = ref(false);

// Selected Values
const guests: any = ref(props.value.guests);
const rooms: any = ref(props.value.rooms);

// Outside click event
onClickOutside(target, () => (dropdown.value = false));

function toggleDropdown(bool: any = null) {
    if (bool === null) {
        dropdown.value = !dropdown.value;
    } else {
        dropdown.value = bool;
    }
}

function addGuests(bool: boolean) {
    if (bool) {
        if (guests.value < 100) {
            guests.value++;
            if (guests.value / 4 > rooms.value) {
                rooms.value++;
            }
        }
    } else {
        if (guests.value > 1) {
            guests.value--;
            if (guests.value < rooms.value) {
                rooms.value--;
            }
        }
    }
    emit('update:value', { guests: guests.value, rooms: rooms.value });
}

function addRooms(bool: boolean) {
    if (bool) {
        if (rooms.value < 100) {
            if (guests.value == rooms.value) {
                guests.value++;
            }
            if (guests.value < 4 || guests.value != rooms.value) {
                rooms.value++;
            }
        }
    } else {
        if (rooms.value > 1) {
            if (guests.value / 4 > rooms.value - 1) {
                guests.value -= 4;
            }
            rooms.value--;
        }
    }
    emit('update:value', { guests: guests.value, rooms: rooms.value });
}
</script>
